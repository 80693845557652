<template>
  <LayoutBase
    :routes="routes"
    :hideTopBar="hideTopBar"
    :sidebarActiveItem="sidebarActiveItem"
  >
    <!-- pass through normal slots -->
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
  </LayoutBase>
</template>

<script>
import { group_names } from '@/shared/constants/PersonaConstants';
import LayoutBase from '../LayoutBase.vue';
import Notifications from '@/shared/api/Notifications';

export default {
  components: { LayoutBase },
  props: {
    sidebarActiveItem: { default: false },
    hideTopBar: { type: Boolean, default: false },
  },
  data() {
    return {
      notifications: {
        dashboard: {},
        order: {},
      },
    };
  },

  computed: {
    routes() {
      const { owner, admin, vendor } = group_names;
      return [
        {
          route: '/dashboard',
          exact: false,
          text: 'Dashboard',
          icon: 'home',
          notifications: 0,
          // notifications: this.notifications.dashboard?.total || 0,
          activeName: 'dashboard',
          allowedGroups: [owner, admin, vendor],
        },
        {
          route: '/product',
          exact: false,
          text: 'Materials & Supplies',
          icon: 'shopping_catalog',
          notifications: 0,
          activeName: 'product',
          allowedGroups: [owner, admin, vendor],
        },
        {
          route: '/order',
          exact: false,
          text: 'Orders',
          icon: 'catalog',
          activeName: 'order',
          notifications: 0,
          allowedGroups: [owner, admin, vendor],
          subRoutes: [
            {
              route: '/approved',
              overrideParentRoute: '/order',
              text: 'RFQs',
              exact: true,
              activeName: 'approved',
            },
            {
              route: '/quoted',
              overrideParentRoute: '/order',
              text: 'Pending Orders',
              exact: true,
              activeName: 'quoted',
            },
            {
              route: '/accepted',
              overrideParentRoute: '/order',
              text: 'Accepted Orders',
              exact: true,
              activeName: 'accepted',
            },
            {
              route: '/completed',
              overrideParentRoute: '/order',
              text: 'Completed Orders',
              exact: true,
              activeName: 'completed',
            },
            // {
            //   route: '/rejected',
            //   overrideParentRoute: '/quote',
            //   text: 'Rejected Orders',
            //   exact: true,
            //   activeName: 'rejected',
            // },
          ],
        },
        {
          route: '/company/member',
          exact: false,
          text: 'Members',
          icon: 'group',
          notifications: 0,
          allowedGroups: [owner, admin],
          activeName: 'member',
        },
        {
          route: '/subscription',
          exact: true,
          text: 'Subscription',
          icon: 'card',
          notifications: 0,
          allowedGroups: [owner, admin],
          activeName: 'subscription',
        },
      ];
    },
  },

  methods: {
    async fetchNotifications() {
      const response = await Notifications.getNotifications();
      this.notifications.dashboard = response.data;
      this.notifications.order = response.data;
    },
  },

  mounted() {
    this.fetchNotifications();
  },
};
</script>
