// to be used
import axios from 'axios';
import { URL_PREFIX } from './variables';

export const updateCompany = (id, ...rest) =>
  axios.post(`${URL_PREFIX}/company/${id}?_method=PUT`, ...rest);
export const postCompany = (...rest) =>
  axios.post(`${URL_PREFIX}/company`, ...rest);
export const getCompany = (id, ...rest) =>
  axios.get(`${URL_PREFIX}/company/${id}`, ...rest);
export const getCompanies = (...rest) =>
  axios.get(`${URL_PREFIX}/company`, ...rest);

export const findCompanyByEmailDomain = (params) =>
  axios.get(`${URL_PREFIX}/company/search`, { params, per_page: 1, headers: { Accept: 'application/json', 'Content-Type': 'application/json' } });

export const searchCompany = (params) =>
  axios.get(`${URL_PREFIX}/company/search`, { params , per_page: 5, headers: { Accept: 'application/json', 'Content-Type': 'application/json' } });

export default {
  updateCompany,
  postCompany,
  getCompany,
  getCompanies,
  findCompanyByEmailDomain,
  searchCompany,
};
