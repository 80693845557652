<template>
  <div class="app-input-checkbox">
    <div
      class="
        app-input-checkbox-container
        d-inline-block d-flex
        align-items-center
      "
      @click="toggleCheckbox"
      @keypress="toggleCheckbox"
    >
      <AppIcon
        :hoverable="!disabled"
        class="app-input-checkbox-checkbox p-2 d-inline-block rounded-circle"
        tabindex="0"
        :name="value ? activeIcon : inactiveIcon"
        :class="value ? `text-primary` : ''"
      ></AppIcon>
      <div
        :for="name"
        class="
          app-input-checkbox-label
          m-0
          d-inline-block
          text-body-2
          font-weight-normal
        "
      >
        <span class="">{{ label }}</span>
        <!-- Required Asterisk -->
        <span v-if="isRequired" class="text-danger">*</span>
      </div>
      <!-- Form Validation Input (maintain app-form-validation class) -->
      <input
        class="app-form-validation"
        type="hidden"
        :value="allValidationsPassed"
      />
    </div>
    <!-- Error Message -->
    <span v-if="!hideError" class="w-100 d-block text-caption text-danger">
      {{ error }}
    </span>
  </div>
</template>

<script>
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import _appErrorValidation from '@/shared/mixins/_appErrorValidation';
import AppIcon from './AppIcon.vue';
export default {
  name: 'AppInput',

  components: { AppIcon },

  mixins: [_appDefaultInput, _appErrorValidation],

  props: {
    value: { type: [Boolean, String, Number], default: false },
    name: { type: String, default: '' },
    label: { type: String, default: '' },
    inactiveIcon: { type: String, default: 'checkbox' },
    activeIcon: { type: String, default: 'checkbox_checked' },
    color: { type: String, default: 'primary' },
    hideError: { type: Boolean, default: false },
    validations: { type: Array, default: () => [] },
    validate: { type: [Number, String, Boolean], default: false },
    disabled: { type: Boolean, default: false },
  },

  data() {
    return {
      focus: false,
    };
  },

  methods: {
    toggleCheckbox() {
      this.innerVal = !this.innerVal;
      if (this.disabled) {
        this.innerVal = false
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_colors';

.app-input-checkbox {
  &-container {
    cursor: pointer;
  }
  &-checkbox {
    transition: background-color 0.2s;
    &:hover {
      @extend .bg-white-dark-1;
    }
  }

  span {
    min-height: 18px;
  }
}
</style>
