<template>
  <AppCard hoverable class="p-3 my-2 shadow-40">
    <div class="row" @click="handleClick">
      <!-- Member Image -->
      <div class="col-3">
        <AppMediaViewer
          aspect-ratio="1/1"
          width="100%"
          class="p-1 rounded-circle"
          :src="user.information ? user.information.profile_picture : null"
        />
      </div>
      <!-- Member Details -->
      <div class="col-7">
        <p class="m-0 font-weight-5">
          {{
            user.information
              ? `${user.information.first_name} ${user.information.last_name}`
              : 'No Name'
          }}
        </p>
        <p class="m-0 text-body-3 text-truncate">{{ user.email }}</p>
        <!-- <p v-if="user.persona" class="m-0 text-body-3 text-truncate">
          {{ user.persona | toTitleCase }}
        </p> -->
        <p v-if="user.groups.length > 0" class="m-0 text-body-3 text-truncate">
          {{ groupsComputed | toTitleCase }}
        </p>
        <p v-else class="m-0 text-body-3 text-truncate">
          {{ 'User' }}
        </p>
        <p
          :class="{
            'text-success': user.is_active,
            'text-warning': !user.is_approved,
            'text-danger': !user.is_active,
          }"
          class="m-0 text-body-3 text-truncate"
        >
          {{ statusText }}
        </p>
      </div>
    </div>
    <div class="row" v-if="canApproveReject">
      <div class="col-12 divider">
        <div class="d-flex justify-content-between align-items-center">
          <AppInputSelect
            v-model="userGroup"
            multiple
            label="Role"
            class="w-50"
            :items="userGroups"
            :shouldSelectFirstItem="false"
          ></AppInputSelect>
          <div>
            <AppBtn :loading="loading" :disabled="loading" text @click="approveUser(user)" class="text-blue small">Accept</AppBtn>
            <AppBtn :loading="removeLoading" :disabled="loading" text @click="removeUser(user)" class="text-danger small">Remove</AppBtn>
          </div>
        </div>
      </div>
      <!-- More Icon -->
      <!-- <div class="col-1">
        <AppDropdown :value="dropdown" @change="dropdownToggled">
          <template v-slot:dropdown-button>
            <AppIcon hoverable name="ellipsis" class="ml-3"></AppIcon>
          </template>
          <template v-slot:dropdown-menu>
            <router-link
              :to="{ name: 'AccountSettingsOther', params: { id: user.id } }"
              class="text-decoration-none dropdown-item"
            >
              Edit User
            </router-link>
            <div
              class="text-decoration-none dropdown-item"
              @click.stop="confirmSuspendAccount"
            >
              {{ suspendTitle }}
            </div>
          </template>
        </AppDropdown>
      </div> -->
    </div>
    <div v-if="vendor">
      <AppDivider color="gray-light-4" class="mt-2 mb-2"></AppDivider>
      <!-- Quotation Details -->
      <div class="mt-1 d-flex align-items-center">
        <AppIcon hoverable name="dot_mark" class="text-special"></AppIcon>
        <b class="mr-1">9</b>
        <span>Quotation Sent</span>
      </div>

      <div class="mt-1 d-flex align-items-center">
        <AppIcon
          hoverable
          name="dot_mark"
          class="text-success-dark-1"
        ></AppIcon>
        <b class="mr-1">9</b>
        <span>Quotation Approve</span>
      </div>
    </div>
    <ActionModal v-model="userSuspendModal" @confirm="toggleSuspendUser">
      <template v-slot:title>{{ suspendTitle }}?</template>
      <template v-slot:description> Click confirm to proceed </template>
    </ActionModal>
  </AppCard>
</template>

<script>
import AppCard from '@/shared/elements/AppCard.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import AppMediaViewer from '@/shared/elements/AppMediaViewer.vue';
import AppDivider from '@/shared/elements/AppDivider.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import AppInputSelect from '@/shared/elements/AppInputSelect.vue';
// import AppDropdown from '@/shared/elements/AppDropdown.vue';
import ActionModal from '@/components/Partial/Modals/ActionModal.vue';
import { updateUser, getUserGroups } from '@/shared/api/Users.js';
import _strings from '@/shared/mixins/_strings';
import { mapGetters } from 'vuex';
import PersonaConstants from '@/shared/constants/PersonaConstants';

export default {
  name: 'MembersCard',

  components: {
    AppCard,
    AppMediaViewer,
    AppIcon,
    AppDivider,
    // AppDropdown,
    ActionModal,
    AppBtn,
    AppInputSelect,
  },

  mixins: [_strings],

  data() {
    return {
      userSuspendModal: false,
      loading: false,
      removeLoading: false,
      userGroups: [],
      userGroup: [],
    };
  },

  props: {
    vendor: { type: Boolean, default: false },
    user: { type: Object, default: null },
    dropdown: { type: Boolean, default: false },
  },

  methods: {
    confirmSuspendAccount() {
      this.userSuspendModal = true;
    },
    toggleSuspendUser() {
      const formData = {
        is_active: !this.user.is_active,
        _method: 'PUT',
      };

      updateUser(this.user.id, formData).then(() => {
        this.$emit('update-user');
      });
    },

    dropdownToggled(val) {
      this.$emit('dropdown-changed', { val: val, userId: this.user.id });
    },

    handleClick() {
      this.$router.push({
        name: 'AccountSettingsOther',
        params: { id: this.user.id },
      });
    },

    async approveUser(user) {
      if (this.userGroup.length <= 0) {
        return;
      }

      this.loading = true;

      await updateUser(user.id, { is_approved: true, groups: this.userGroup, _method: 'PUT' });
      this.$emit('approve-user');

      this.loading = false;
    },

    async removeUser(user) {
      if (this.userGroup.length <= 0) {
        return;
      }

      this.removeLoading = true;

      await updateUser(user.id, { is_approved: false,  is_active: false, groups: this.userGroup,_method: 'PUT' });
      this.$emit('remove-user');

      this.removeLoading = false;
    },

    async fetchUserGroup() {
      const companyType = this.user.persona;
      const persona = PersonaConstants[companyType];
      if (persona && persona.group_names) {
        const owner = persona.group_names.find((group) => group.value === 'owner');
        const response = await getUserGroups();

        this.userGroups = response.data
          .filter(
            (group) =>
              group.name !== owner.name &&
              persona.group_names.find((constGroup) => constGroup.name === group.name)
          )
          .map((group) => ({
            text: group.name,
            value: group.id,
          }));
      }

      this.userGroup = this.user.groups.map(a => a.id);
    },
  },

  computed: {
    ...mapGetters('auth', { currentUser: 'user' }),

    suspendTitle() {
      const title = this.user.is_active ? 'Deactivate' : 'Activate';

      return `${title} User`;
    },

    groupsComputed() {
      let groups = this.user.groups
        .map((group) => {
          return group.name;
        })
        .join(' ');

      const isOneWord = groups.trim().split(' ').length === 1;

      if (isOneWord) {
        groups += ' User';
      }

      return groups;
    },

    statusText() {
      if (!this.user.is_active) return 'Deactivated';
      if (!this.user.is_approved) return 'Pending Approval';

      return 'Active';
    },

    isAdminOrOwner() {
      let groups = this.currentUser.groups;

      if (groups === null || groups.length === 0 || !groups) {
        return false;
      }

      return groups.some((group) => {
        return ['Administrator', 'Owner'].includes(group.name);
      });
    },

    canApproveReject() {
      return (!this.user.is_approved && this.user.is_active) && this.isAdminOrOwner
    },
  },

  async mounted() {
    if (this.canApproveReject) {
      await this.fetchUserGroup();
    }
  },
};
</script>

<style lang="scss" scoped>
.text-blue {
  color: #3F69FF;
}

.divider {
  border-top: 1px solid #F1F1F1;
  margin-top: 16px;
  padding-top: 16px;
}

.small {
  padding: 0 8px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}

.bg-gray-light-2[data-v-50d074ba], .hoverable[data-v-50d074ba]:hover, .alert-gray-light-2[data-v-50d074ba] {
  background-color: #fafafa !important;
}
</style>
