<template>
  <LayoutVendor>
    <template v-slot:title>Members</template>

    <template v-slot:topbar>
      <div
        v-if="isRoleAllowed([group_names.admin, group_names.owner])"
        class="ml-2"
      >
        <!-- Desktop Button -->
        <router-link :to="{ name: 'MemberAdd' }">
          <AppBtn class="text-uppercase mr-3 d-none d-md-inline-block">
            Add Member
          </AppBtn>
        </router-link>
      </div>
    </template>

    <div class="members h-100">
      <!-- Tab Options -->
      <!-- <AppTabs v-model="currentTab" :items="tabOptions"></AppTabs> -->
      <p v-if="!company" class="text-danger text-center">
        Warning: this user doesn't have any company
      </p>
      <!-- Member List -->
      <div class="members-list m-auto p-3 pl-md-5 pr-md-5">
        <!-- <AppInput
          hideError
          appendIcon="search"
          placeholder="Search Name..."
        ></AppInput> -->
        <!-- Sort and Filter -->
        <!-- <div class="mt-3 d-flex justify-content-between">
          <SortBtn :items="sortItems"></SortBtn>
          <FilterBtn></FilterBtn>
        </div> -->

        <router-link :to="{ name: 'MemberAdd' }">
          <AppBtn class="text-uppercase ml-3 d-md-none d-inline-block">
            Add Member
          </AppBtn>
        </router-link>

        <AppLoading v-if="!members"></AppLoading>
        <div v-else class="row mt-3">
          <div
            v-for="user in members"
            :key="`member-item-${user.id}`"
            class="col-12 col-md-6 mb-4"
          >
            <MembersCard
              @update-user="fetchMembers"
              @dropdown-changed="dropdownChanged"
              :dropdown="dropdownId === user.id"
              :user="user"
              @approve-user="fetchMembers"
              @remove-user="fetchMembers"
            ></MembersCard>
          </div>
        </div>
      </div>
    </div>
  </LayoutVendor>
</template>

<script>
// import FilterBtn from '@/components/Partial/FilterBtn.vue';
// import SortBtn from '@/components/Partial/SortBtn.vue';
import MembersCard from '@/components/Vendor/Members/MembersCard.vue';
// import AppInput from '@/shared/elements/AppInput.vue';
// import AppTabs from '@/shared/elements/AppTabs.vue';
import Company from '@/shared/api/Company';
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import AppBtn from '@/shared/elements/AppBtn.vue';
import LayoutVendor from '@/components/Partial/Layouts/LayoutVendor/LayoutVendor.vue';
import { isRoleAllowed } from '@/router/AuthorizationGate';
import { group_names } from '@/shared/constants/PersonaConstants';

export default {
  components: {
    // SortBtn,
    // AppInput,
    MembersCard,
    // AppTabs,
    // FilterBtn,
    LayoutVendor,
    AppBtn,
  },
  name: 'AdminVendorMember',

  mixins: [_appApiHelper],

  data() {
    return {
      group_names,
      company: {},
      members: [],
      tabOptions: ['Active Members', 'Inactive Members'],
      sortItems: [
        { text: 'Alphabetical A-Z', value: 'sort_by_name_asc' },
        { text: ' Alphabetical Z-A', value: 'sort_by_name_desc' },
        { text: ' Most Quote Sent', value: 'most_quote_sent' },
        { text: ' Most Quote Approved', value: 'most_quote_approved' },
      ],
      currentTab: 0,
      dropdownId: null,
      memberCardLoading: null,
    };
  },

  watch: {
    currentTab() {
      this.fetchMembers();
    },
  },

  methods: {
    isRoleAllowed,
    changeTab(index) {
      this.currentTab = index;
    },
    async fetchMembers() {
      const user = this.$store.getters['auth/user'];

      const params = {};

      if (user.company_id) {
        const apiResponse = await Company.getCompany(user.company_id, {
          params,
        });
        if (this.error) {
          this.error =
            'There was something wrong with your request please try again later';
        } else {
          this.company = apiResponse.data;
          this.members = apiResponse.data.users;
          this.$store.dispatch('auth/refreshSelf');
        }
      } else {
        this.company = false;
        this.members = [];
      }
    },
    dropdownChanged(payload) {
      if (payload.userId === this.dropdownId) {
        this.dropdownId = null;
        return;
      }

      this.dropdownId = payload.userId;
    },
  },

  mounted() {
    this.fetchMembers();
  },
};
</script>

<style lang="scss" scoped>
.members {
  &-list {
    max-width: 1200px;
  }
}
</style>
