<template>
  <div
    class="app-input"
    :class="{ 'app-input-focus': focus }"
    :style="{ '--input-hover-color': `var(--${color})` }"
  >
    <!-- Label -->
    <label v-if="label" :for="name" class="app-input-label">
      <span>{{ label }}</span>
      <span v-if="isRequired" class="text-danger">*</span>
    </label>

    <div class="position-relative">
      <div class="app-input-container" @click="onClick" @keypress="onClick">
        <!-- Prepend Icon -->
        <div class="app-input-icon-prepend">
          <slot name="prepend-icon">
            <AppIcon v-if="prependIcon" :name="prependIcon"></AppIcon>
          </slot>
        </div>
        <!-- Multiple Value Inputs -->
        <div v-if="multiple" class="w-100 d-inline-flex flex-wrap">
          <div
            v-for="(item, index) in displayText"
            :key="`app-input-item-${item}-${index}`"
            class="
              p-0
              pl-1
              pr-1
              m-md-0
              mb-1
              ml-1 ml-md-1
              bg-gray-dark-6
              text-body-2 text-white
              rounded
              d-flex
              align-items-center
              justify-content-center
            "
            style="word-break: break-word"
          >
            <span>{{ item }}</span>
            <AppIcon
              hoverable
              size="12"
              name="close"
              @click="removeItem(index)"
            ></AppIcon>
          </div>
          <span v-if="!innerVal" class="text-gray-dark-2">
            {{ $attrs.placeholder || '' }}
          </span>
        </div>
        <!-- Input Element -->
        <input
          v-else
          :disabled="disabled === true"
          readonly
          ref="inputField"
          type="text"
          :value="displayText"
          v-bind="$attrs"
          :name="name"
        />
        <!-- Form Validation Input (maintain app-form-validation class) -->
        <input
          class="app-form-validation"
          type="hidden"
          :value="allValidationsPassed"
        />
        <!-- Select Icon -->
        <div class="app-input-icon-prepend">
          <AppIcon :name="focus ? activeIcon : inactiveIcon"></AppIcon>
        </div>
        <!-- Append Icon -->
        <div v-if="appendIcon" class="app-input-icon-prepend">
          <slot name="append-icon">
            <AppIcon :name="appendIcon"></AppIcon>
          </slot>
        </div>
      </div>
      <!-- Select Options -->
      <transition name="app-input-select-options">
        <ul
          v-show="focus && items.length > 0"
          class="app-input-select-options bg-white shadow-40 w-100"
        >
          <li
            class="app-input-select-option-search bg-white sticky-top"
            @click="preventClose"
          >
            <slot name="option-header"></slot>
          </li>
          <li
            v-if="searchable"
            class="app-input-select-option-search p-3 bg-white sticky-top"
            @click="preventClose"
          >
            <AppInput
              v-model="searchFilter"
              hideError
              color="black"
              prependIcon="search"
              type="text"
              class="bg-gray-light-3"
              :placeholder="searchPlaceholder"
            />
          </li>
          <li
            v-for="(item, index) in filteredItems || items"
            tabindex="0"
            :key="`app-input-select-option-${index}-${
              item.value || item.text || item
            }`"
            class="p-1 pl-3 pr-3"
            @click="selectItem(item)"
            @keypress="selectItem(item, true)"
          >
            <slot name="option-item" :item="item">
              <AppInputCheckbox
                v-if="multiple"
                :value="isSelected(item)"
                hideError
                class="d-inline-block"
                @click="selectItem(item)"
              ></AppInputCheckbox>
              <span class="pt-1 pb-1 d-inline-block">{{
                item.text || item.value || item
              }}</span>
            </slot>
          </li>
          <li
            class="app-input-select-option-footer p-1 bg-white position-sticky"
            @click="preventClose"
          >
            <slot name="option-footer"></slot>
          </li>
        </ul>
      </transition>
    </div>
    <!-- Error Message -->
    <span v-if="!hideError" class="app-input-error">
      {{ error }}
    </span>
    <!-- Disabled Error Message -->
    <span v-if="showDisabledText" class="app-input-error">
      {{ onClickDisabledText }}
    </span>
  </div>
</template>

<script>
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import _appErrorValidation from '@/shared/mixins/_appErrorValidation';

import AppIcon from './AppIcon.vue';
import AppInputCheckbox from './AppInputCheckbox.vue';
import AppInput from './AppInput.vue';
export default {
  name: 'AppInputSelect',

  components: { AppIcon, AppInputCheckbox, AppInput },

  mixins: [_appDefaultInput, _appErrorValidation],

  props: {
    value: { type: [String, Number, Array, Object], default: '' },
    items: { type: Array, default: () => [] },
    name: { type: String, default: '' },
    label: { type: String, default: '' },
    prependIcon: { type: String, default: '' },
    inactiveIcon: { type: String, default: 'caret_down' },
    activeIcon: { type: String, default: 'caret_up' },
    appendIcon: { type: String, default: '' },
    color: { type: String, default: 'primary' },
    hideError: { type: Boolean, default: false },
    validations: { type: Array, default: () => [] },
    validate: { type: [Number, String, Boolean], default: false },
    multiple: { type: Boolean, default: false },
    searchable: { type: Boolean, default: false },
    searchPlaceholder: { type: String, default: '' },
    disabled: { default: false },
    shouldSelectFirstItem: { type: Boolean, default: true },
    searchValue: { type: String, default: '' },
    onClickDisabledText: { type: String, default: '' },
  },

  data() {
    return {
      focus: false,
      preventCloseFlag: false,
      searchFilter: '',
      showDisabledText: false,
    };
  },

  computed: {
    filteredItems() {
      if (this.searchFilter) {
        const newItems = this.items.filter((value) => {
          const item = value.text || value.value || value;
          return item
            .toString()
            .toLowerCase()
            .includes(this.searchFilter.toString().toLowerCase());
        });

        this.$emit('searched', newItems);

        return newItems;
      }

      return null;
    },
    displayText() {
      if (this.innerVal) {
        const value = this.innerVal;
        const items = this.items;
        if (this.multiple) {
          const displayables = this.innerVal.map((val) => {
            const valueItem = items.find((itemVal) => {
              const itemValInner = itemVal.value || itemVal.text || itemVal;
              return itemValInner == val;
            });

            return valueItem.text || valueItem.val || valueItem;
          });
          if (displayables.length > 0) {
            return displayables;
          }
        } else {
          const displayable = this.items.find(
            (val) => val.value === value || val === value
          );
          if (displayable) {
            return displayable.text || displayable.value || displayable;
          }
        }
      }
      return this.multiple ? [] : '';
    },
  },

  watch: {
    items(value) {
      if (value.length > 0 && !this.disabled && this.shouldSelectFirstItem) {
        this.selectFirstItem();
      }
    },
    disabled: {
      handler(value) {
        if (value) {
          this.innerVal = null;
        } else if (this.shouldSelectFirstItem) {
          this.selectFirstItem();
        }
      },
      immediate: true,
    },
    searchFilter: {
      handler(value) {
        this.$emit('onSearchChange', value);
      }
    }
  },

  methods: {
    openOptions() {
      this.focus = true;
      this.showDisabledText = false;
      setTimeout(() => {
        document.addEventListener('click', this.closeOptions, false);
      }, 0);
    },
    closeOptions() {
      if (this.preventCloseFlag) {
        this.preventCloseFlag = false;
      } else {
        this.focus = false;
        setTimeout(() => {
          document.removeEventListener('click', this.closeOptions, false);
        }, 0);
      }
    },
    preventClose() {
      this.preventCloseFlag = this.focus;
      // this.focus = this.focus;
    },
    selectItem(item, keyPress = false) {
      if (this.multiple) {
        this.preventClose();
      } else if (keyPress) {
        this.closeOptions();
      }

      const newVal = item.value || item.text || item;
      if (this.multiple) {
        if (!this.innerVal) {
          this.innerVal = [newVal];
        } else if (this.innerVal.includes(newVal)) {
          this.innerVal = this.innerVal.filter((item) => item !== newVal);
        } else {
          this.innerVal = [...this.innerVal, newVal];
        }
      } else {
        this.innerVal = newVal;
      }

      this.showDisabledText = false;
    },
    isSelected(item) {
      if (this.innerVal) {
        const itemVal = item.value || item.text || item;
        if (this.multiple) {
          return this.innerVal.findIndex((val) => val == itemVal) > -1;
        } else {
          // this.items.find((val) => {
          //   if (item.value) {
          //     return val.value === item.value;
          //   } else if (item.text) {
          //     return val.text === item.text;
          //   } else {
          //     return val === item;
          //   }
          // });
          // return this.innerVal.includes(itemVal);
          return this.innerVal === itemVal;
        }
      }
    },
    removeItem(index) {
      this.preventClose();
      this.innerVal.splice(index, 1);
    },
    selectFirstItem() {
      if (!this.innerVal && this.items.length > 0) {
        const item = this.items[0];
        const newVal = item.value || item.text || item;
        if (this.multiple) {
          if (!this.innerVal) {
            this.innerVal = [newVal];
          } else if (this.innerVal.includes(newVal)) {
            this.innerVal = this.innerVal.filter((item) => item !== newVal);
          } else {
            this.innerVal = [...this.innerVal, newVal];
          }
        } else {
          this.innerVal = newVal;
        }
      }
    },
    onClick() {
      if (this.disabled && this.onClickDisabledText) {
        this.showDisabledText = true;
        return;
      }

      return this.disabled || this.preventCloseFlag
        ? null
        : this.focus
        ? this.closeOptions()
        : this.openOptions();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_inputs';
</style>
